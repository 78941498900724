<template>
	<div :style="'height: 100%; display: flex; flex-direction: column;'">
		<div class="scroll-wrapper" style="height: 100%; padding-right: 10px; overflow: auto;">
			<el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="showdelivery({order_id: order.id})" plain>创建交付</el-button>
			<el-timeline style="margin-top: 10px;" v-if="deliveries">
				<el-timeline-item v-for="delivery in deliveries" :key="delivery.id" :timestamp="delivery.created_at" placement="top">
					<div>
						{{delivery.employee.name}}老师 {{delivery.delivery_at}} 完成《{{services[delivery.service]||'未知服务'}}》{{delivery.offline ? '线下交付' : '线上交付'}}
						<p style="font-size: 12px; color: #999; margin-top: 5px;">{{delivery.remarks}}</p>
						<div class="attachments" v-if="delivery.attachments">
							<template v-for="attachment in delivery.attachments">
								<el-image fit="cover" class="f_attachment" :key="attachment.id" :src="attachment.url" @click="openFile(attachment.url)">
									<i slot="error" class="el-icon-document" @click="openFile(attachment.url)"></i>
								</el-image>
							</template>
						</div>
					</div>
				</el-timeline-item>
			</el-timeline>
			<el-divider v-else>暂无记录</el-divider>
		</div>

		<el-dialog width="420px" title="添加交付" :visible.sync="delivery_dialog" @closed="delivery = {}" append-to-body destroy-on-close>
			<el-form label-width="80px" ref="delivery_form" :model="delivery" :rules="delivery_rules" :size="theme.size" status-icon>
				<el-form-item label="交付类型" prop="offline">
					<el-switch v-model="delivery.offline" active-text="线下" inactive-text="线上"></el-switch>
				</el-form-item>
				<el-form-item label="服务内容" prop="service">
					<el-select v-model="delivery.service" placeholder="请选择服务内容" style="width: 100%;" clearable filterable>
						<el-option v-for="(type, t) in services" :label="type" :value="t" :key="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="交付时间" prop="delivery_at">
					<el-date-picker type="datetime" placeholder="选择日期时间" v-model="delivery.delivery_at" :editable="false"></el-date-picker>
				</el-form-item>
				<el-form-item label="备注说明" prop="remarks">
					<el-input type="textarea" v-model="delivery.remarks" placeholder="请输入备注说明。提交后不能修改" rows="3" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item label="上传附件" prop="attachments">
					<el-upload
						ref="services_upload"
						list-type="picture"
						:action="$api.URI_DELIVERIES_SERVICES"
						:data="{type: service_attach_cfg.type}"
						:accept="service_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
						:on-success="(r, f, l) => { onSuccess(l); }"
						:on-remove="(f, l) => { delivery = {...delivery, attachments: l}; }"
						:before-upload="beforeServiceAttach"
						:file-list="delivery.attachments"
						:limit="9"
						multiple>
						<el-button type="text" icon="el-icon-upload">上传附件</el-button>
						<p slot="tip" class="el-upload__tip" style="font-size: 12px; line-height: 1.5;">只能上传 {{service_attach_cfg.ext.join('/').toUpperCase()}} 文件，且单文件大小不超过 {{service_attach_cfg.format_size_unit}}</p>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('delivery_form')">确认提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<style type="text/css">
	.delivery .el-descriptions__header {
		margin-bottom: 0;
	}
	.delivery .el-descriptions__title {
		color: #666;
		font-size: 14px;
	}
</style>

<script>
	import { mapState } from 'vuex';

	export default {
		props: {
			order: {
				type: Object
			}
		},
		computed: {
			...mapState(['theme']),
			service_attach_cfg () {
				return this.$utils.uploadConfig('services');
			}
		},
		filters: {
			format_price (value) {
				return (value/100).toFixed(2);
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			onSuccess (list) {
				this.delivery = {
					...this.delivery,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			showdelivery (r) {
				this.delivery = {...r};
				this.delivery_dialog = true;
			},
			beforeServiceAttach (file) {
				const { size, format_size_unit } = this.service_attach_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传跟进附件不能超过 '+format_size_unit);
				return is;
			},
			async submitDeliveries (data) {
				const res = await this.$http.post(this.$api.URI_DELIVERIES_SERVICES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getDeliveries(this.params);
						this.delivery_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'delivery_form':
							this.submitDeliveries(this.delivery);
						break;
					}
				});
			},
			async getDeliveries (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_DELIVERIES_SERVICES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.deliveries = result.data;
				this.services = result.services;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				services: [],
				deliveries: [],
				delivery: {},
				delivery_dialog: false,
				delivery_rules: {
					service: [{ required: true, message: '请选择服务类型', trigger: 'blur' }],
					delivery_at: [{ required: true, message: '请选择服务时间', trigger: 'blur' }],
					remarks: [{ required: true, message: '请填写备注说明', trigger: 'blur' }],
				},
				params: {
					perPage: 10
				}
			}
		},
		mounted () {
			const { id: order_id } = this.order;
			this.getDeliveries({...this.params, order_id});

		}
	};
</script>