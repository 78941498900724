<template>
	<el-dialog width="680px" title="销售订单" :visible.sync="show_dialog" :fullscreen="fullscreen" :custom-class="fullscreen ? 'fullscreen' : 'ufullscreen'" :close-on-click-modal="false" append-to-body destroy-on-close>
		<el-tabs class="order-tabs" v-model="tab_active" v-if="show_dialog">
			<el-tab-pane label="开单信息" name="basic" class="scroll-wrapper" style="padding-right: 10px; overflow: auto;">
				<el-descriptions class="expense">
					<template slot="title">
						<el-link type="primary" style="font-size: 16px; font-weight: bold;" @click="$emit('edit', { ...order, field: 'address', regions: order.regions.map((r) => r.id), goods_price: order.goods_price/100, freight_price: order.freight_price/100, discount_price: order.discount_price/100, paid_price: order.paid_price/100})" :disabled="order.status === 'closed'">No.{{order.no}}</el-link>
						<p style="font-size: 12px; display: inline-block; padding-left: 10px;">订单创建于 {{order.created_at}}</p>
					</template>
					<template slot="extra">
						<el-button type="text" size="mini" :disabled="!$utils.update($api.URI_ORDERS) || $utils.closeOrder(order.status)" @click="onClose(order)">关闭</el-button>
					</template>
					<el-descriptions-item :label="order.source">{{order.out_no || 'N/A'}}</el-descriptions-item>
					<el-descriptions-item label="产品金额">{{order.goods_price/100}} 元</el-descriptions-item>
					<el-descriptions-item label="配送运费">{{order.freight_price/100}} 元</el-descriptions-item>
					<el-descriptions-item label="优惠金额">{{order.discount_price/100}} 元</el-descriptions-item>
					<el-descriptions-item label="实付金额">{{order.paid_price/100}} 元</el-descriptions-item>
					<el-descriptions-item label="状态">
						<el-link type="primary" @click="onStatus(order)" :disabled="order.status === 'closed'">{{order.order_statuses ? (order.order_statuses[order.status] || '未知') : order.status}}</el-link>
					</el-descriptions-item>
					<el-descriptions-item label="备注说明">{{order.remarks || '/'}}</el-descriptions-item>
				</el-descriptions>
				<div style="padding: 5px 10px; background-color: #fafafa; line-height: 1.5;" v-if="order.linkman && order.mobile">
					<p>{{order.linkman}}，{{order.mobile}}</p>
					<p>{{order.address}}</p>
				</div>
				<el-divider content-position="left"><h3><b>服务老师</b> <el-button type="text" @click="waiter_dialog = true; waiter = {common_type: 'App\\Models\\Order', common_id: order.id}" v-if="employees">添加</el-button></h3></el-divider>
				<el-descriptions :column="5">
					<el-descriptions-item label="开发" v-if="order.seller">{{order.seller.name}}</el-descriptions-item>
					<template v-if="order.waiters">
						<el-descriptions-item v-for="waiter in order.waiters" :key="waiter.id" :label="order.waiter_types ? (order.waiter_types[waiter.type] || '未知') : waiter.type">
							<el-popconfirm title="确定删除这个服务老师吗？" @confirm="handleWaiter({action: 'delete', id: waiter.id}, () => { open(order); })">
								<el-link type="primary" slot="reference">{{waiter.employee ? waiter.employee.name : '无'}}</el-link>
							</el-popconfirm>
						</el-descriptions-item>
					</template>
				</el-descriptions>
				<el-table class="scroll-wrapper" v-if="order.order_goods" :data="order.order_goods" :size="theme.size">
					<el-table-column label="产品" min-width="200">
						<template slot-scope="scope"> <template v-if="scope.row.goods && scope.row.goods.brand">{{scope.row.goods.brand.name}} </template>{{scope.row.title}}</template>
					</el-table-column>
					<el-table-column label="编码" min-width="100" prop="number"></el-table-column>
					<el-table-column label="售价" min-width="100" :formatter="({shop_price}) => { return (shop_price/100).toFixed(2)+' 元' }"></el-table-column>
					<el-table-column label="优惠" min-width="80" :formatter="({discount_price}) => { return ((discount_price || 0)/100).toFixed(2)+' 元' }"></el-table-column>
					<el-table-column label="数量" min-width="60" prop="quantity"></el-table-column>
					<el-table-column label="重量" min-width="80" :formatter="({weight}) => { return weight || '虚拟' }"></el-table-column>
				</el-table>
				<!-- <el-button type="text" size="small" disabled>添加</el-button> -->
				<!-- <el-button type="text" size="small" disabled>导出</el-button> -->
				<el-button type="text" size="small" @click="$refs['delivery_dialog'].open({ order_id: order.id, order_no: order.no, company_code: order.express, name: order.linkman, mobile: order.mobile, address: order.address })">配送</el-button>
				<el-card v-if="order.contract">
					<el-descriptions class="expense" :column="2">
						<template slot="title">
							<el-link type="primary" style="font-size: 16px; font-weight: bold;" @click="$emit('show-contract', {...order.contract, order_no: order.no, order})">No.{{order.contract.no}}</el-link>
							<p style="font-size: 12px; display: inline-block; padding-left: 10px;">合同创建于 {{order.contract.created_at}}</p>
						</template>
						<template slot="extra">
							<el-button type="text" size="small" @click="$emit('contract-status', order.contract)">{{order.contract_statuses ? (order.contract_statuses[order.contract.status] || '未知') : order.contract.status}}</el-button>
						</template>
						<el-descriptions-item label="成交金额">{{order.contract.price/100}} 元</el-descriptions-item>
						<el-descriptions-item label="有效日期">{{order.contract.start_at | format_at}} 至 {{order.contract.end_at | format_at}}</el-descriptions-item>
						<el-descriptions-item label="乙方代理">{{order.contract.employee ? order.contract.employee.name : '无'}}</el-descriptions-item>
						<el-descriptions-item label="甲方代理">{{order.contract.person}}</el-descriptions-item>
						<el-descriptions-item label="手机号码">{{order.contract.mobile}}</el-descriptions-item>
						<el-descriptions-item label="联系地址">{{order.contract.address}}</el-descriptions-item>
					</el-descriptions>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="回款记录" name="fees" lazy>
				<client-fees :param="{common_type: 'order', common_id: order.id, common_no: order.no}" v-if="tab_active === 'fees'"></client-fees>
			</el-tab-pane>
			<el-tab-pane label="开票申请" name="invoices" lazy>
				<order-invoices :order="order" v-if="tab_active === 'invoices'"></order-invoices>
			</el-tab-pane>
			<el-tab-pane label="付款申请" name="payments" lazy>
				<order-payments :order="order" v-if="tab_active === 'payments'"></order-payments>
			</el-tab-pane>
			<el-tab-pane label="费用报销" name="expenses" lazy>
				<order-expenses :order="order" v-if="tab_active === 'expenses'"></order-expenses>
			</el-tab-pane>
			<el-tab-pane label="交付服务" name="deliveries" lazy>
				<order-deliveries :order="order" v-if="tab_active === 'deliveries'"></order-deliveries>
			</el-tab-pane>
		</el-tabs>
		<el-dialog width="480px" title="修改订单" :visible.sync="edit_dialog" :fullscreen="theme.width < 480" :close-on-click-modal="false" append-to-body destroy-on-close>
			<el-form ref="order_form" label-width="80px" style="max-height: 56vh; padding-right: 10px; overflow: auto;" class="scroll-wrapper" :model="edit" :size="theme.size" :disabled="!$utils.update($api.URI_ORDERS) || $utils.editOrder(edit.status)" status-icon>
				<el-form-item prop="field" label="修改字段" v-if="edit.id" required>
					<el-select v-model="edit.field" placeholder="请选择修改字段" filterable>
						<el-option label="优惠金额" value="discount_price" :disabled="edit.status != 'paying'"></el-option>
						<el-option label="配送运费" value="freight_price" :disabled="edit.status != 'paying'"></el-option>
						<el-option label="实付金额" value="paid_price" :disabled="edit.status != 'paying'"></el-option>
						<el-option label="收货信息" value="address"></el-option>
					</el-select>
				</el-form-item>
				<template v-if="edit.field === 'address'">
					<el-form-item prop="linkman" label="收货人" :rules="[{ required: true, message: '必须填写收货人', trigger: ['blur', 'change']}]">
						<el-input v-model="edit.linkman" placeholder="请输入收货人">
							<!-- <el-select slot="prepend" style="width: 100px;" placeholder="关联用户" v-model="edit.user_id" v-if="is_order_form_user" filterable clearable>
								<el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id"></el-option>
							</el-select> -->
						</el-input>
					</el-form-item>
					<el-form-item prop="mobile" label="手机号码" :rules="[{ required: true, message: '必须填写手机号码', trigger: ['blur', 'change']}, { pattern: /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/, message: '请填写正确手机号码', trigger: ['blur', 'change']}]">
						<el-input v-model="edit.mobile" placeholder="请输入手机号码">
							<!-- <el-select slot="prepend" style="width: 100px;" placeholder="关联店铺" v-model="edit.shop_id" v-if="is_order_form_shop" filterable clearable>
								<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
							</el-select> -->
						</el-input>
					</el-form-item>
					<el-form-item prop="regions" label="收货地区" :rules="[{ required: true, message: '必须选择收货地区', trigger: ['blur', 'change']}]">
						<el-cascader style="width: 100%;" placeholder="请选择订单收货地区。支持输入搜索" v-model="edit.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable></el-cascader>
					</el-form-item>
					<el-form-item prop="address" label="详细地址" :rules="[{ required: true, message: '必须填写详细地址', trigger: ['blur', 'change']}]">
						<el-input v-model="edit.address" placeholder="请输入收货详细地址" clearable>
							<!-- <el-select slot="prepend" style="width: 100px;" v-model="edit.express" placeholder="物流平台" filterable>
								<el-option v-for="(express, e) in expresses" :key="e" :label="express" :value="e"></el-option>         
							</el-select> -->
						</el-input>
					</el-form-item>
				</template>
				<el-form-item label="优惠金额" prop="discount_price" v-if="edit.field === 'discount_price'" :rules="[{ required: true, message: '必须填写优惠金额'}, { type: 'number', min: 0, max: edit.goods_price, message: '优惠金额必须大于等于0或小于等于'+edit.goods_price, trigger: ['blur', 'change']}]">
					<el-input-number v-model.number="edit.discount_price" placeholder="请输入修改后的金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
				</el-form-item>
				<el-form-item label="配送运费" prop="freight_price" v-if="edit.field === 'freight_price'" :rules="[{ required: true, message: '必须填写配送运费'}, { type: 'number', min: 0, message: '配送运费必须为大于等于0', trigger: ['blur', 'change']}]">
					<el-input-number v-model.number="edit.freight_price" placeholder="请输入修改后的金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
				</el-form-item>
				<el-form-item label="实付金额" prop="paid_price" v-if="edit.field === 'paid_price'" :rules="[{ required: true, message: '必须填写实付金额'}, { type: 'number', min: 1, message: '实付金额必须为大于等于1', trigger: ['blur', 'change']}]">
					<el-input-number v-model.number="edit.paid_price" placeholder="请输入修改后的金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('order_form')">提交保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog width="480px" title="添加老师" :visible.sync="waiter_dialog" :fullscreen="theme.width < 480" :close-on-click-modal="false" append-to-body destroy-on-close>
			<el-form ref="waiter_form" label-width="80px" style="max-height: 56vh; padding-right: 10px; overflow: auto;" class="scroll-wrapper" :model="waiter" :rules="waiter_rules" :size="theme.size" status-icon>
				<el-form-item prop="type" label="类型">
					<el-select v-model="waiter.type" placeholder="请选择类型" filterable>
						<el-option v-for="(type, t) in order.waiter_types" :label="type" :value="t" :key="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="employee_id" label="老师">
					<el-select v-model="waiter.employee_id" placeholder="请选择类型" filterable>
						<el-option v-for="employee in employees" :label="employee.name" :value="employee.id" :key="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('waiter_form')">提交分配</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<show-delivery ref="delivery_dialog" :warehouses="order.warehouses" :expresses="order.expresses" @refresh="getDeliveries(params)"></show-delivery>
	</el-dialog>
</template>


<style type="text/css">
	.expense .el-descriptions__header {
		margin-bottom: 5px;
	}
	.expense .el-descriptions__title {
		color: #666;
		font-size: 16px;
	}
	.fullscreen .el-dialog__body {
		height: calc(100% - 54px);
	}
	.ufullscreen .el-dialog__body {
		height: calc(100vh - 30vh);
	}
	.order-tabs {
		height: 100%;
		display: flex;
		margin-top: -30px;
		flex-direction: column;
	}
	.order-tabs .el-tabs__content,
	.order-tabs .el-tab-pane {
		height: 100%;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import orderInvoices from './invoices';
	import orderPayments from './payments';
	import orderExpenses from './expenses';
	import orderDeliveries from './deliveries';
	import showDelivery from '@/pages/wms/deliveries/show';
	import clientFees from '@/pages/crm/clients/fees';

	export default {
		components: {
			orderInvoices,
			orderPayments,
			orderExpenses,
			orderDeliveries,
			showDelivery,
			clientFees,
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		computed: {
			...mapState(['theme', 'regions', 'orders']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			fullscreen () {
				if (this.$utils.isiPad()) return true;
				if (this.$utils.isMobile()) return true;
				if (this.theme.width < 750) return true;
				return false;
			},
			sources () {
				if (!this.orders) return [];
				return this.orders.sources;
			},
			// 编辑或添加订单是否需要选择用户
			is_order_form_user () {
				const { field, source, out_no } = this.edit;
				// 修改地址
				if (field == 'address') return false;
				// 添加内部订单
				if (source == 'inside' && out_no != '') return false;
				return true;
			},
			// 编辑或添加订单是否需要选择店铺
			is_order_form_shop () {
				const { field, source, out_no } = this.edit;
				const { shop_id } = this.admin_user;
				// 修改地址
				if (field == 'address') return false;
				// 添加内部订单
				if (source == 'inside' && out_no != '') return false;
				// 当前为店铺模式
				if (shop_id) return false;
				return true;
			}
		},
		props: {
			employees: {
				type: Object
			}
		},
		methods: {
			async open (r) {
				// this.order = {...r};
				if (r.id) {
					const res = await this.$http.get(this.$api.URI_ORDERS, {params: {action: 'show', id: r.id}, headers: {loading: true}});
					const { code, msg, result } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.order = { ...result };
				}
				this.show_dialog = true;
				this.tab_active = 'basic';
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'order_form':
							var { id, user_id, shop_id } = this.edit;
							// if (!express) return this.$message.error("必须选择物流平台！");
							// 添加订单
							if (!id) {
								if (this.is_order_form_user && !user_id) return this.$message.error("必须选择关联用户！");
								if (this.is_order_form_shop && !shop_id) return this.$message.error("必须选择关联店铺！");
							}
							this.submitOrder(this.edit);
						break;
						case 'waiter_form':
							this.submitWaiter(this.waiter, () => {
								this.waiter_dialog = false;
								this.open(this.order);
							});
						break;
					}
				});
			},
			onDelete (r) {
				this.$confirm('No.'+r.no, '确定要删除订单吗？', {
					dangerouslyUseHTMLString: false,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleOrder({ action: 'delete', id: r.id }, () => {
						this.$emit('refresh');
					});
				});
			},
			onClose (r) {
				this.$confirm('No.'+r.no, '确定要关闭订单吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleOrder({ action: 'close', id: r.id }, () => {
						this.open(r);
					});
				});
			},
			async handleOrder (params, onClose) {
				const res = await this.$http.get(this.$api.URI_ORDERS, {params, headers: {loading: true}});
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({ message, onClose });
			},
			async submitOrder (data) {
				const res = await this.$http.post(this.$api.URI_ORDERS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.edit_dialog = false;
						this.open(this.edit)
					}
				});
			},
			async handleWaiter (params, onClose) {
				const res = await this.$http.get(this.$api.URI_WAITERS, {params, headers: {loading: true}});
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({ message, onClose });
			},
			async submitWaiter (data, onClose) {
				const res = await this.$http.post(this.$api.URI_WAITERS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({ message, onClose });
			},
			onStatus (r) {
				const { status } = r;
				switch (status) {
					case 'paying':
						this.$emit('show-contract', {order_no: r.no, price: r.paid_price/100, max_price: r.paid_price/100, regions: r.regions.map((r) => r.id), address: r.address});
					break;

					default:
						this.open(r);
					break;
				}
			}
		},
		data() {
			return {
				edit: {},
				order: {},
				waiter: {},
				show_dialog: false,
				edit_dialog: false,
				waiter_dialog: false,
				tab_active: 'basic',
				waiter_rules: {
					type: [{ required: true, message: '请选择服务类型', trigger: 'blur' }],
					employee_id: [{ required: true, message: '请选择服务老师', trigger: 'blur' }],
				}
			}
		}
	};
</script>
