<template>
	<div style="height: 100%; display: flex; flex-direction: column;">
		<div class="scroll-wrapper" style="height: 100%; padding-right: 10px; overflow: auto;">
			<el-button type="primary" size="mini" icon="el-icon-edit-outline" v-if="param.common_type == 'order'" @click="$refs['fee_dialog'].open({source_type: 'App\\Models\\Order', source_no: param.common_no})" plain>添加回款</el-button>
			<template v-if="fees.length">
				<el-descriptions class="fee" v-for="fee in fees" :key="fee.no" :size="theme.size" border>
					<template slot="title">
						<el-link type="primary" style="font-size: 16px; font-weight: bold;" @click="$refs['fee_dialog'].open({...fee, source_no: fee.source ? fee.source.no : '', price: fee.price/100})">No.{{fee.no}}</el-link>
						<p style="font-size: 12px; display: inline-block; padding-left: 10px;" v-if="fee.employee">{{fee.employee.name}} 创建于 {{fee.created_at}}</p>
					</template>
					<template slot="extra">
						<el-button type="text" size="mini" @click="$refs['fee_dialog'].delFee(fee)" :disabled="!$utils.delete($api.URI_CLIENTS_FEES)">删除</el-button>
					</template>
					<el-descriptions-item label="类型">{{types[fee.type] || '未知'}}</el-descriptions-item>
					<el-descriptions-item label="金额">{{fee.price/100 + ' 元'}}</el-descriptions-item>
					<el-descriptions-item label="状态">
						<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+fee.approval.sp_no" v-if="fee.approval">
							<el-link type="primary" @click="$refs['fee_dialog'].onStatus({...fee, source_no: fee.source ? fee.source.no : '', price: fee.price/100})">{{statuses[fee.status] || '未知'}}</el-link>
						</el-tooltip>
						<el-link type="primary" @click="$refs['fee_dialog'].onStatus({...fee, source_no: fee.source ? fee.source.no : '', price: fee.price/100})" v-else>{{statuses[fee.status] || '未知'}}</el-link>
					</el-descriptions-item>
					<el-descriptions-item label="支付时间">{{fee.paid_at || '无'}}</el-descriptions-item>
					<el-descriptions-item :label="sources[fee.source_type] || '模型'" v-if="fee.source">{{fee.source.no}}</el-descriptions-item>
					<el-descriptions-item label="备注说明">{{fee.comment || '/'}}</el-descriptions-item>
				</el-descriptions>
			</template>
			<el-divider v-else>暂无记录</el-divider>
		</div>
		<fee-show ref="fee_dialog" @refresh="getClientFees(params)"></fee-show>
	</div>
</template>

<style type="text/css">
	.fee {
		margin-bottom: 20px;
	}
	.fee .el-descriptions__header {
		color: #666;
		margin-bottom: 0;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import feeShow from '../fees/show';

	export default {
		components: {
			feeShow
		},
		props: {
			param: {
				type: Object
			},
		},
		computed: {
			...mapState(['theme', 'client_fees']),
			types () {
				if (!this.client_fees) return [];
				return this.client_fees.types;
			},
			sources () {
				if (!this.client_fees) return [];
				return this.client_fees.sources;
			},
			statuses () {
				if (!this.client_fees) return [];
				return this.client_fees.statuses;
			}
		},
		methods: {
			async getClientFees (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CLIENTS_FEES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.fees = result.data;
				this.params = {
					...params,
					total: result.total,
					page: result.current_page,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				fees: [],
				params: {}
			}
		},
		mounted () {
			if (!this.param.common_id) return false;
			this.getClientFees({...this.params, ...this.param});
		}
	};
</script>