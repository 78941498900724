<template>
	<el-dialog width="420px" :title="delivery.id ? '编辑配送' : '添加配送'" :visible.sync="delivery_dialog" @closed="delivery = {}" append-to-body destroy-on-close>
			<el-form ref="create_form" label-width="80px" :model="delivery" :size="theme.size" status-icon>
				<el-form-item label="销售订单" prop="order_no">
					<el-input v-model="delivery.order_no" autocomplete="off" placeholder="请输入销售订单" :disabled="delivery.order_id"></el-input>
				</el-form-item>
				<el-form-item label="出货仓库" prop="warehouse_id" :rules="[{ required: true, message: '请选择出货仓库', trigger: 'blur' }]">
					<el-select v-model="delivery.warehouse_id" placeholder="请选择出货仓库" filterable>
						<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="配送快递" prop="company_code" :rules="[{ required: true, message: '请选择快递', trigger: 'blur' }]">
					<el-select v-model="delivery.company_code" placeholder="请选择快递" filterable clearable>
						<el-option v-for="(express, s) in expresses" :key="s" :label="express" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="联系人" prop="name" :rules="[{ required: true, message: '请输入联系人', trigger: 'blur' }]">
					<el-input v-model="delivery.name" autocomplete="off" placeholder="请输入联系人"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="mobile" :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' }]">
					<el-input v-model="delivery.mobile" autocomplete="off" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="地址" prop="address" :rules="[{ required: true, message: '请输入地址详情', trigger: 'blur' }]">
					<el-input v-model="delivery.address" autocomplete="off" placeholder="请输入地址"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remarks">
					<el-input type="textarea" rows="3" v-model="delivery.remarks" placeholder="用一段话简单描述备注说明。" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
				</el-form-item>
			</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		props: {
			warehouses: {
				type: Array
			},
			expresses: {
				type: Array
			}
		},
		methods: {
			async open (delivery = {}) {
				this.delivery = { ...delivery };
				this.delivery_dialog = true;
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'create_form':
							this.submitDeliveries(this.delivery);
						break;
						default:
							this.$message.error("操作未知表单");
						break;
					}
				})
			},
			async submitDeliveries (data) {
				const res = await this.$http.post(this.$api.URI_MATERIALS_DELIVERIES, data);
				const { code, msg: message, } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose: () => {
						this.$emit('refresh');
						this.delivery_dialog = false;
					}
				});
			}
		},
		data() {
			return {
				delivery: {},
				delivery_dialog: false,
			}
		}
	};
</script>