<template>
	<div style="'height: 100%; display: flex; flex-direction: column;">
		<div class="scroll-wrapper" style="height: 100%; padding-right: 10px; overflow: auto;">
			<el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="showInvoice({common_type: 'App\\Models\\Order', type: 1, common_id: order.id, common_no: order.no })" plain>创建申请</el-button>
			<template v-if="invoices.length">
				<el-descriptions class="invoice" style="margin-top: 10px;" v-for="invoice in invoices" :key="invoice.no" :size="theme.size" border>
					<template slot="title">
						<el-link type="primary" style="font-size: 16px; font-weight: bold;" @click="showInvoice({...invoice, common_no: invoice.common ? invoice.common.no : '', amount: invoice.amount/100})">No.{{invoice.no}}</el-link>
						<p style="font-size: 12px; display: inline-block; padding-left: 10px;">{{invoice.employee.name}} 创建于 {{invoice.created_at}}</p>
					</template>
					<template slot="extra">
						<el-link type="primary" :disabled="!$utils.delete($api.URI_INVOICES)" @click="$refs['invoice_show'].onRestore(invoice)">删除</el-link>
					</template>
					<el-descriptions-item label="销售公司">{{invoice.seller.name}}</el-descriptions-item>
					<el-descriptions-item label="类型">{{types[invoice.type]}}</el-descriptions-item>
					<el-descriptions-item label="状态">
						<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+invoice.approval.sp_no" v-if="invoice.approval">
							<el-link type="primary" @click="$refs['invoice_show'].onStatus(invoice)">{{statuses[invoice.status] || '未知'}}</el-link>
						</el-tooltip>
						<el-link type="primary" @click="$refs['invoice_show'].onStatus(invoice)" v-else>{{statuses[invoice.status] || '未知'}}</el-link>
					</el-descriptions-item>
					<el-descriptions-item label="购买公司">{{invoice.name}}</el-descriptions-item>
					<el-descriptions-item label="金额">{{invoice.amount | format_price}} 元</el-descriptions-item>
					<el-descriptions-item label="备注说明">{{invoice.remarks || '/'}}</el-descriptions-item>
				</el-descriptions>
			</template>
			<el-divider v-else>暂无记录</el-divider>
		</div>
		<invoice-show ref="invoice_show" :types="types" :models="common_types" :companies="companies" @refresh="getinvoices(params)"></invoice-show>
	</div>
</template>

<style type="text/css">
	.invoice .el-descriptions__header {
		margin-bottom: 5px;
	}
	.invoice .el-descriptions__title {
		color: #666;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import invoiceShow from '@/pages/fms/invoices/show';

	export default {
		components: {
			invoiceShow
		},
		props: {
			order: {
				type: Object
			}
		},
		computed: {
			...mapState(['theme'])
		},
		filters: {
			format_price (value) {
				return (value/100).toFixed(2);
			}
		},
		methods: {
			showInvoice (r) {
				this.$refs['invoice_show'].open(r);
				this.invoice = { ...r };
				this.invoice_dialog = true;
			},
			async getInvoices (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_INVOICES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.invoices = result.data;
				this.types = result.types;
				this.statuses = result.statuses;
				this.companies = result.companies;
				this.common_types = result.common_types;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				types: {},
				invoices: [],
				companies: [],
				common_types: {},
				params: {
					perPage: 10
				}
			}
		},
		mounted () {
			const { id: order_id } = this.order;
			this.getInvoices({...this.params, order_id});

		}
	};
</script>
